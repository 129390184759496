import {Component, ViewChild, AfterViewInit, Input, Output, EventEmitter} from '@angular/core';
import {KSSwiperContainer, KSSwiperSlide} from 'angular2-swiper';
import { optionsCategories } from './options-categories';
import { ProductService } from './product.service';

@Component({
  selector: 'options-categories-slider',
  templateUrl: 'options-categories-slider.component.html' 
})

export class optionsCategoriesSlider{ 
  productStylesOptions: any;
  optionsCategories: optionsCategories[];
  @Input() currentCategory : number;

  @Output() baseProductClicked:EventEmitter<number> = new EventEmitter<number>();

  // this is how you get access to the child component
  @ViewChild(KSSwiperContainer) swiperContainer: KSSwiperContainer;


  constructor(private _productService: ProductService) {
    this.productStylesOptions = {
        nextButton: '.options-categories-button-next',
        prevButton: '.options-categories-button-prev',
        slidesPerView: 3,
        centeredSlides: true,
        paginationClickable: true,
        direction: 'vertical'
    };
  }
  ngOnInit(): void{
    this.optionsCategories = this._productService.getOptionsCategories();
  }

  moveNext() {
    this.swiperContainer.swiper.slideNext();
  }

  movePrev() {
    this.swiperContainer.swiper.slidePrev();
  }

  onSelect(basicProduct){
    console.log('Basic:' + basicProduct.id);
    this.currentCategory = basicProduct.id;
    this.baseProductClicked.emit(this.currentCategory);
  }

  ngAfterViewInit() {
    console.log(this.swiperContainer);
   }
}

