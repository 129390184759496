import {NgModule}      from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpModule}    from '@angular/http';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule, MatIconModule, MatButtonModule, MatSelectModule, MatCardModule} from '@angular/material'

import {CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';


import {AppComponent}  from './app.component';

import { DaliDraggable } from './directives/draggable.directive';
import { CanvasInteractionDirective } from './directives/canvasinteraction.directive';

import { categorySlider}  from './category-slider.component';
import { productStylesSlider}  from './product-styles-slider.component';
import { productOptionsSlider}  from './product-options-slider.component';
import { optionsCategoriesSlider}  from './options-categories-slider.component';
import { wallChangeLeft}  from './wall-change-left.component';
import { wallChangeRight}  from './wall-change-right.component';

import { ImageUploadModule } from "angular2-image-upload";

// import { NgDragDropModule } from 'ng-drag-drop';
import { OwlModule } from 'ngx-owl-carousel';
// import { SwiperModule } from 'ngx-swiper-wrapper';
// import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { NgxCarousel, NgxCarouselModule } from 'ngx-carousel';
import 'hammerjs';

// const SWIPER_CONFIG: SwiperConfigInterface = {
//   direction: 'horizontal',
//   slidesPerView: 'auto',
//   keyboardControl: true
// };

@NgModule({
  imports:[
    BrowserModule, 
    BrowserAnimationsModule,
    MatMenuModule, MatIconModule, MatButtonModule, MatSelectModule, MatCardModule, 
    FormsModule,HttpModule, HttpClientModule,
    			OwlModule,
          NgxCarouselModule, 
          ImageUploadModule.forRoot(),
          
          // NgDragDropModule.forRoot(),
    			// SwiperModule.forRoot(SWIPER_CONFIG)
          ],
  declarations: [AppComponent, categorySlider, productStylesSlider, productOptionsSlider, DaliDraggable, 
  				 optionsCategoriesSlider, wallChangeLeft, wallChangeRight, CanvasInteractionDirective],
  bootstrap:    [AppComponent],
  providers: [NgxCarousel],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
